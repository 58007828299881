import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
  Grid,
  Collapse,
  IconButton,
  Icon,
  Link,
  Button,
} from '@mui/material';
import { Drawer } from '@mui/material';
import * as React from 'react';
import { drawerWidth } from '../LocationsFilter/LocationsFilter';

//@ts-ignore
import randomTrack from '../../assets/randomTrack.jpg';

import PlaceIcon from '@mui/icons-material/Place';
import SportsIcon from '@mui/icons-material/Sports';
import TimerIcon from '@mui/icons-material/Timer';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PinIcon from '@mui/icons-material/Pin';
import CloseIcon from '@mui/icons-material/Close';

import {
  getSportsLocation,
  SportsLocation,
} from '../../services/SportsLocation/SportsLocation.service';

import useSWR from 'swr';
import { StringParam, useQueryParam } from 'use-query-params';
import { copyToClipboard } from '../../utils/utils';
import DownloadLocationPlan from './DownLoadLocationPlan';
import Licensing from './Licensing';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
type SmartracksLocationInfoProps = {
  sportsLocation: SportsLocation;
  onRegionSelected?: (regionFilter: string | number[] | undefined) => void;
};
type SmartracksEnabledFunctionalAreas = {
  roundAreaLaneNumbers?: number[];
  sprintAreaLaneNumbers?: number[];
  longJumpAreaLaneNumbers?: number[];
};

type FuntionalAreaLaneInfo = {
  laneNumber: number;
  timingGatesArray: Array<number>;
  realLengthInM: number;
  shortName?: string;
};
type TimingGatesDistances = {
  roundAreaTimingGates?: Array<FuntionalAreaLaneInfo>;
  sprintAreaTimingGates?: Array<FuntionalAreaLaneInfo>;
  longJumpAreaTimingGates?: Array<FuntionalAreaLaneInfo>;
};

const SmartracksLocationInfo = (props: SmartracksLocationInfoProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [retryCount, setRetryCount] = React.useState<number>(0);
  const { data, error } = useSWR<SportsLocation>(
    props.sportsLocation.sportsLocationGUID,
    getSportsLocation,
    {
      onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
        if (!retryCount) return;
        setRetryCount(retryCount);
        if (retryCount >= 3) return;
        if (error.status === 404) return;

        // retry after 5 seconds
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );

  const { sportsLocation } = props;

  const [drawerState, setDrawerState] = React.useState(true);
  const [facility, setFacility] = useQueryParam('facility', StringParam);

  const toggleDrawer = (open: boolean) => {
    setDrawerState(open);
  };

  const address = [
    sportsLocation.addresses[0].streetAndNumber,
    sportsLocation.addresses[0].postalCode,
    sportsLocation.addresses[0].city,
  ]
    .filter((x) => x)
    .join(', ');

  let areasInfo: SmartracksEnabledFunctionalAreas = {
    roundAreaLaneNumbers: [] || undefined,
    sprintAreaLaneNumbers: [] || undefined,
    longJumpAreaLaneNumbers: [] || undefined,
  };

  let timingGatesInfo: TimingGatesDistances = {
    roundAreaTimingGates: [] || undefined,
    sprintAreaTimingGates: [] || undefined,
    longJumpAreaTimingGates: [] || undefined,
  };

  if (data) {
    if (data.functionalAreas) {
      const roundTrackAreas = data.functionalAreas.filter(
        (area) => area.areaType === 'RoundTrackArea'
      );

      const sprintAreas = data.functionalAreas.filter(
        (area) => area.areaType === 'SprintArea'
      );

      const longJumpAreas = data.functionalAreas.filter(
        (area) => area.areaType === 'LongJumpArea'
      );

      if (roundTrackAreas.length !== 0) {
        const activatedLanes = roundTrackAreas
          .flatMap((a) => a.lanes)
          .filter((l) => l !== undefined && l.smarTracksEnabled);
        areasInfo.roundAreaLaneNumbers = activatedLanes
          .map((l) => l!.laneNumberFromLeft)
          .filter((v, idx, arr) => arr.indexOf(v) === idx);
        timingGatesInfo.roundAreaTimingGates = activatedLanes?.map(
          (a, idxOut) => {
            const lengthToAdd =
              a!.timingGates.sort(
                (a, b) => a.itemAtInMeter - b.itemAtInMeter
              )[0]?.itemAtInMeter * -1 ?? 0;
            return {
              laneNumber: a!.laneNumberFromLeft,
              shortName:
                roundTrackAreas.find((area) =>
                  area.lanes?.some((l) => l.id === a!.id)
                )?.shortName ?? undefined,
              realLengthInM: a!.realLengthInMeter,
              timingGatesArray: a!.timingGates
                .sort(
                  (a, b) =>
                    a.itemAtInMeter +
                    lengthToAdd -
                    b.itemAtInMeter +
                    lengthToAdd
                )
                .map((t, idx) => Math.floor(t.itemAtInMeter + lengthToAdd))
                .sort((a, b) => a - b),
            };
          }
        );

        timingGatesInfo.roundAreaTimingGates?.forEach((e) => {
          if (e.timingGatesArray[0] !== 0) e.timingGatesArray.reverse();
          if (e.timingGatesArray[e.timingGatesArray.length] !== e.realLengthInM)
            e.timingGatesArray.push(Math.floor(e.realLengthInM));
        });
      }
      if (sprintAreas.length !== 0) {
        const activatedLanes = sprintAreas
          .flatMap((s) => s.lanes)
          ?.filter((l) => l !== undefined && l.smarTracksEnabled);
        areasInfo.sprintAreaLaneNumbers = activatedLanes
          .map((l) => l!.laneNumberFromLeft)
          .filter((v, idx, arr) => arr.indexOf(v) === idx);
        timingGatesInfo.sprintAreaTimingGates = activatedLanes?.map(
          (a, idxOut) => {
            const lengthToAdd =
              a!.timingGates.sort(
                (a, b) => a.itemAtInMeter - b.itemAtInMeter
              )[0]?.itemAtInMeter * -1 ?? 0;
            return {
              laneNumber: a!.laneNumberFromLeft,
              shortName:
                sprintAreas.find((area) =>
                  area.lanes?.some((l) => l.id === a!.id)
                )?.shortName ?? undefined,
              realLengthInM: a!.realLengthInMeter,
              timingGatesArray: a!.timingGates
                .sort(
                  (a, b) =>
                    a.itemAtInMeter +
                    lengthToAdd -
                    b.itemAtInMeter +
                    lengthToAdd
                )
                .map((t, idx) => Math.floor(t.itemAtInMeter + lengthToAdd))
                .sort((a, b) => a - b),
            };
          }
        );
      }
      if (longJumpAreas.length !== 0) {
        const activatedLanes = longJumpAreas
          .flatMap((s) => s.lanes)
          ?.filter((l) => l !== undefined && l.smarTracksEnabled);
        areasInfo.longJumpAreaLaneNumbers = activatedLanes
          .map((l) => l!.laneNumberFromLeft)
          .filter((v, idx, arr) => arr.indexOf(v) === idx);

        timingGatesInfo.longJumpAreaTimingGates = activatedLanes?.map(
          (a, idxOut) => {
            const lengthToAdd =
              a!.timingGates.sort(
                (a, b) => a.itemAtInMeter - b.itemAtInMeter
              )[0]?.itemAtInMeter * -1 ?? 0;
            return {
              laneNumber: a!.laneNumberFromLeft,
              shortName:
                longJumpAreas.find((area) =>
                  area.lanes?.some((l) => l.id === a!.id)
                )?.shortName ?? undefined,
              realLengthInM: a!.realLengthInMeter,
              timingGatesArray: a!.timingGates
                .sort(
                  (a, b) =>
                    a.itemAtInMeter +
                    lengthToAdd -
                    b.itemAtInMeter +
                    lengthToAdd
                )
                .map((t, idx) => Math.floor(t.itemAtInMeter + lengthToAdd))
                .sort((a, b) => a - b),
            };
          }
        );
      }
    }
  }

  const listItem = [
    { title: 'address', text: `${address}` },
    // { title: 'sports', text: 'footbal,basketball "placeHolder" ' },
    {
      title: 'timingGates',
      text: [
        `${
          (areasInfo.roundAreaLaneNumbers?.length as number) > 0
            ? ((areasInfo.roundAreaLaneNumbers?.length as number) === 1
                ? t('roundLane')
                : t('roundLanes')) +
              ' ' +
              areasInfo.roundAreaLaneNumbers?.join(' | ')
            : ''
        } `,
        `
      ${
        (areasInfo.sprintAreaLaneNumbers?.length as number) > 0
          ? (areasInfo.sprintAreaLaneNumbers?.length === 1
              ? t('sprintLane')
              : t('sprintLanes')) +
            ' ' +
            areasInfo.sprintAreaLaneNumbers?.join(' | ')
          : ''
      }
      `,
        `${
          (areasInfo.longJumpAreaLaneNumbers?.length as number) > 0
            ? (areasInfo.longJumpAreaLaneNumbers?.length === 1
                ? t('longJumpLane')
                : t('longJumpLanes')) +
              ' ' +
              areasInfo.longJumpAreaLaneNumbers?.join(' | ')
            : ''
        }`,
      ],
    },
    // { title: 'locationType', text: 'soccerField "placeHolder"' },
    //year of construction
    {
      title: 'yoc',
      text: data?.originalConstructionDate
        ? `
     ${data?.originalConstructionDate?.substring(0, 10)}`
        : '',
    },
    // { title: 'surfaceProvider', text: `${'constrComp "placeHolder" '}` },
    // { title: 'mcdNumber', text: 'mcdNumb "placeHolder" ' },
  ];

  const collapseListItems = [
    {
      title: t('roundLane'),
      gatesObject: timingGatesInfo.roundAreaTimingGates,
    },
    {
      title: t('sprintLane'),
      gatesObject: timingGatesInfo.sprintAreaTimingGates,
    },
    {
      title: t('longJumpLane'),
      gatesObject: timingGatesInfo.longJumpAreaTimingGates,
    },
  ];

  const distancesStringConstructor = (laneInfos: FuntionalAreaLaneInfo[]) =>
    laneInfos.map(
      (laneInfo) =>
        `${t('lane')} ${laneInfo.laneNumber}${
          laneInfo.shortName !== undefined
            ? ' (' + laneInfo.shortName + ')'
            : ''
        }: ${laneInfo.timingGatesArray.join(', ')}`
    );

  function decideIcon(itemName: string) {
    switch (itemName) {
      case 'address':
        return <PlaceIcon />;
      case 'locationName':
        return;
      case 'sports':
        return <SportsIcon />;
      case 'timingGates':
        return <TimerIcon />;
      case 'locationType':
        return <NotListedLocationIcon />;
      case 'yoc':
        return <DateRangeIcon />;
      case 'surfaceProvider':
        return <AddBusinessIcon />;
      case 'mcdNumber':
        return <PinIcon />;
      default:
        return <Box></Box>;
    }
  }

  const [subMenuState, setSubMenuState] = React.useState(false);

  const toggleSubMenu = (open: boolean) => {
    setSubMenuState(open);
  };

  return (
    <Stack>
      <Drawer
        sx={{
          zIndex: mobileScreen ? theme.zIndex.drawer : theme.zIndex.drawer + 2,
          overflow: 'hidden',
        }}
        anchor={mobileScreen ? 'bottom' : 'left'}
        open={drawerState}
        onClose={() => toggleDrawer(false)}
        ModalProps={{ keepMounted: true }}
        hideBackdrop={true}
        variant="persistent"
      >
        {!data && !error && (
          <Box
            sx={{
              width: drawerWidth,
              paddingX: theme.spacing(0),
              overflowY: 'clip',
              flexGrow: 1,
              position: 'relative',
            }}
          >
            <Skeleton
              sx={{ marginBottom: theme.spacing(0.5) }}
              variant="rectangular"
              width="100%"
              height="150px"
            />
            <Skeleton variant="text" width="100%" height="70px" />
            {listItem.map((item, idx) => (
              <Box
                key={`${item.title}+${idx}`}
                sx={{
                  paddingLeft: '25px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {decideIcon(item.title)}
                <Skeleton
                  sx={{ marginBottom: theme.spacing(0.5) }}
                  variant="text"
                  width="70%"
                  height="40px"
                />
              </Box>
            ))}
            {/*<DownloadLocationPlan sportsLocation={data!} />*/}
          </Box>
        )}

        {!data && error && retryCount < 3 && (
          <Box sx={{ width: drawerWidth }}>
            <Skeleton
              sx={{ marginBottom: theme.spacing(0.5) }}
              variant="rectangular"
              width="100%"
              height="150px"
            />
            <Typography sx={{ paddingLeft: '40px !important' }}>
              An error occurred, retrying...
            </Typography>
            <Skeleton variant="text" width="100%" height="70px" />
            {listItem.map((item, idx) => (
              <Box
                key={`${item.title}+${idx}`}
                sx={{
                  paddingLeft: '25px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {decideIcon(item.title)}
                <Skeleton
                  sx={{ marginBottom: theme.spacing(0.5) }}
                  variant="text"
                  width="70%"
                  height="40px"
                />
              </Box>
            ))}
          </Box>
        )}

        {(data || (!data && error && retryCount >= 3)) && (
          <>
            {mobileScreen && (
              <>
                <Grid container spacing={2} direction="row">
                  <Grid
                    item
                    xs={4}
                    ml={1}
                    pt={2}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'flex-end'}
                  >
                    <CloseIcon
                      onClick={() => {
                        if (facility) setFacility(undefined);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      alignSelf: 'flex-start',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography
                      sx={{
                        alignItems: 'flex-start',

                        paddingTop: 1.5,
                      }}
                      color={'black'}
                    >
                      Location Info
                    </Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
                <Divider sx={{ borderWidth: 1 }} />
              </>
            )}

            {!mobileScreen && (
              <Box
                component="img"
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  maxWidth: `${drawerWidth - 10} !important`,
                  maxHeight: '170px !important',
                  display: 'flex',
                  alighnItems: 'center',
                }}
                alt="platzhalter"
                src={randomTrack}
              />
            )}
            <Box sx={{ margin: 1 }}>
              <Typography
                onDoubleClick={() =>
                  copyToClipboard(
                    props.sportsLocation.sportsLocationGUID as string
                  )
                }
                sx={{
                  textAlign: 'center',
                  paddingBottom: '5px',
                  paddingTop: '5px',
                }}
                variant={'subtitle2'}
                color={theme.palette.primary.main}
              >
                {`${sportsLocation.names[0].shortName}`}
              </Typography>
              <Divider variant="middle" />
            </Box>
            <List
              sx={{
                width: drawerWidth,
                paddingX: theme.spacing(3),
                overflowY: 'scroll',
                flexGrow: 1,
                position: 'relative',
              }}
            >
              {listItem.map((item, idx) => (
                <div key={`${item.title}+${idx}`}>
                  <ListItem
                    disablePadding
                    sx={{
                      marginLeft: '5px',
                    }}
                  >
                    <ListItemButton
                      onClick={() =>
                        item.title === 'timingGates'
                          ? toggleSubMenu(!subMenuState)
                          : null
                      }
                    >
                      <ListItemIcon onClick={() => console.log(item.text)}>
                        {item.title !== 'timingGates'
                          ? (item.text as string).trim() === ''
                            ? null
                            : decideIcon(item.title)
                          : (item.text as string[]).every(
                              (e) => e.trim() === 'no'
                            )
                          ? undefined
                          : decideIcon(item.title)}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ userSelect: 'text', fontSize: '0.5rem' }}
                        primary={
                          item.title !== 'timingGates' ? (
                            (item.text as string).trim() === '' ? null : (
                              <p style={{ fontSize: '0.71rem' }}>{item.text}</p>
                            )
                          ) : (item.text as string[]).every(
                              (e) => e.trim() === 'no'
                            ) ? null : (
                            (item.text as string[]).map((e, idx) =>
                              e.trim() === 'no' ? null : (
                                <p
                                  key={'timingGates' + idx}
                                  style={{ fontSize: '0.71rem' }}
                                >
                                  {e}
                                </p>
                              )
                            )
                          )
                        }
                      />
                    </ListItemButton>
                    {item.title === 'timingGates' && (
                      <IconButton>
                        <Icon>
                          <KeyboardArrowDownIcon
                            sx={{
                              transform: subMenuState
                                ? 'rotate(0.5turn)'
                                : 'default',
                              transition: '0.3s',
                            }}
                          />
                        </Icon>
                      </IconButton>
                    )}
                  </ListItem>
                  {item.title === 'timingGates' && (
                    <Collapse
                      in={subMenuState}
                      timeout="auto"
                      unmountOnExit
                      sx={{ mb: 2, pb: 2 }}
                    >
                      <List component="div" disablePadding>
                        {collapseListItems.map(
                          (item, idx) =>
                            (item.gatesObject?.length ?? 0) > 0 && (
                              <ListItemButton sx={{ pl: 6 }} key={idx}>
                                <ListItemText
                                  secondary={
                                    (item.gatesObject?.length ?? 0) > 0
                                      ? distancesStringConstructor(
                                          item.gatesObject!
                                        ).map((item, idx) => <p>{item}</p>)
                                      : ''
                                  }
                                  primary={item.title}
                                />
                              </ListItemButton>
                            )
                        )}
                      </List>
                    </Collapse>
                  )}
                </div>
              ))}
              <ListItem>
                <Link
                  target="blank"
                  href="https://smartracks.run/safety-instructions/"
                >
                  {t('visitSafetyInstructions')}
                </Link>
              </ListItem>
            </List>
            <Stack
              maxWidth={drawerWidth - 10}
              flexWrap="wrap"
              gap={1}
              px={1}
              direction={'row'}
              justifyContent="space-around"
              alignItems="center"
            >
              <Button
                href={`mailto:support@smartracks.run?subject=${encodeURIComponent(
                  'Edit suggestion on SmarTracks Locations for ' +
                    sportsLocation?.names[0].shortName +
                    ' (' +
                    (sportsLocation?.sportsLocationGUID ?? 'no guid') +
                    ')'
                )}`}
                variant="outlined"
                sx={{
                  height: '30px',
                  borderColor: '#A9A9A9',
                  color: '#696969',
                  borderRadius: '10px',
                  maxWidth: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {t('suggestEdit')}
              </Button>
              <DownloadLocationPlan sportsLocation={sportsLocation!} />
            </Stack>
            <Licensing
              downloadStarted={true}
              sportsLocation={sportsLocation!}
            />
          </>
        )}
      </Drawer>
      {drawerState && !mobileScreen && (
        <Box
          zIndex={theme.zIndex.drawer + 3}
          sx={{
            position: 'relative',
            left: drawerWidth,
            top: 174,
            backgroundColor: 'white',
            maxWidth: '30px !important',
            maxHeight: '30px !important',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            borderTopRightRadius: '10px 10px',
            borderBottomRightRadius: '10px 10px',
          }}
          onClick={() => {
            setDrawerState(false);
            setFacility(undefined);
          }}
        >
          <CloseIcon />
        </Box>
      )}
    </Stack>
  );
};

export default SmartracksLocationInfo;
